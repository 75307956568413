@media screen and (max-width: 500px) {
    .new_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 4rem 0;
        }
    }
}

@media screen and (min-width: 501px) and (max-width: 880px) {
    .new_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 6rem 0;
        }
    }
}

@media screen and (min-width: 881px) and (max-width: 1260px) {
    .new_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 6rem 0;
        }
    }
}

@media screen and (min-width: 1261px) {
    .new_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 6rem 0;
        }
    }
}

.new_container{
    .content{
        // max-width: 1640px;
        // margin:4rem auto;
        // padding: 6rem 0;
        .title{
            color: #000;
            font-size: 48px;
            font-weight: 600;
            height: 67px;
            line-height: 67px;
            text-align: center;
        }

        .btn_wrapper{
            text-align: right;
            margin: 0.5rem 0 3rem;
            .history_btn{
                width: 102px;
                height: 36px;
                color: #4D4D4D;
                font-size: 12px;
                font-weight: 400;
                border: 1px solid #AEAEAE;
                border-radius: 9999px;
            }

            .history_btn:hover{
                border: 1px solid #333333;
            }
        }
        
    }
}
