@media screen and (max-width: 768px) {
    .technology_container{
        max-width: 1640px;
        padding: 8rem 0;
        margin: auto;
    
        .technology_wrapper{
            margin: auto;
            img{
            }
            .technology_desc{
                background: linear-gradient(to bottom,rgba(252, 126, 0, 0.85),rgba(255, 153, 51, 0.64));
                backdrop-filter: blur(15px);
                padding: 2rem;
                text-align: center;
    
                .title{
                    line-height: 56px;
                    color: #FFFFFF;
                    font-size: 40px;
                    font-weight: 500;
                }
    
                .detail{
                    // width: 451px
                    // height: 168px;
                    line-height: 26px;
                    color: #FDFDFD;
                    font-size: 16px;
                    letter-spacing: 2px;
                    margin: 2rem auto;
                }
    
                button{
                    width: 108px;
                    height: 38px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 48px;
                    border: 1px solid #FFFFFF;
                    margin: auto;
                    text-align: center;
                }
    
                button:hover{
                    background-color: #FF701F;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .technology_container{
        max-width: 1640px;
        padding: 6rem 0;
        margin: auto;
    
        .technology_wrapper{
            // width: 60%;
            width: 917px;
            height: 404px;
            margin: auto;
            margin-left: 10%;
            position: relative;
            transform: scale(0.7);
            transform-origin: center;
            img{
                width: 917px;
                height: 404px;
            }
            .technology_desc{
                width: 593px;
                height: 404px;
                position: absolute;
                background: linear-gradient(to bottom,rgba(252, 126, 0, 0.85),rgba(255, 153, 51, 0.64));
                backdrop-filter: blur(15px);
                top: 0;
                left: -30%;
                padding: 2rem 4rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
    
                .title{
                    line-height: 56px;
                    color: #FFFFFF;
                    font-size: 40px;
                    font-weight: 500;
                    // text-align: center;
                }
    
                .detail{
                    // width: 451px;
                    // height: 168px;
                    line-height: 26px;
                    color: #FDFDFD;
                    font-size: 16px;
                    letter-spacing: 2px;
                    margin: 2rem auto;
                }
    
                button{
                    width: 108px;
                    height: 38px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 48px;
                    border: 1px solid #FFFFFF;
                    // margin: auto;
                    text-align: center;
                }
    
                button:hover{
                    background-color: #FF701F;
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .technology_container{
        max-width: 1640px;
        padding: 6rem 0;
        margin: auto;
    
        .technology_wrapper{
            width: 917px;
            height: 404px;
            margin: auto;
            margin-left: 15%;
            position: relative;
            transform: scale(0.8);
            img{
                width: 917px;
                height: 404px;
            }
            .technology_desc{
                width: 593px;
                height: 404px;
                position: absolute;
                background: linear-gradient(to bottom,rgba(252, 126, 0, 0.85),rgba(255, 153, 51, 0.64));
                backdrop-filter: blur(15px);
                top: 0;
                left: -30%;
                padding: 2rem 4rem;
                text-align: center;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                .title{
                    line-height: 56px;
                    color: #FFFFFF;
                    font-size: 40px;
                    font-weight: 500;
                    z-index: 123;
                    // text-align: center;
                }
    
                .detail{
                    // width: 451px;
                    // height: 168px;
                    line-height: 26px;
                    color: #FDFDFD;
                    font-size: 16px;
                    letter-spacing: 2px;
                    margin: 2rem auto;
                }
    
                button{
                    width: 108px;
                    height: 38px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 48px;
                    border: 1px solid #FFFFFF;
                    // margin: auto;
                    text-align: center;
                }
    
                button:hover{
                    background-color: #FF701F;
                }
            }
        }
    }
}


@media screen and (min-width: 1441px) {
    .technology_container{
        max-width: 1640px;
        padding: 8rem 0;
        margin: auto;
    
        .technology_wrapper{
            width: 917px;
            height: 404px;
            margin: auto;
            margin-left: 30%;
            position: relative;
            img{
                width: 917px;
                height: 404px;
            }
            .technology_desc{
                width: 593px;
                height: 404px;
                position: absolute;
                background: linear-gradient(to bottom,rgba(252, 126, 0, 0.85),rgba(255, 153, 51, 0.64));
                backdrop-filter: blur(15px);
                top: 0;
                left: -30%;
                padding: 2rem 4rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                .title{
                    line-height: 56px;
                    color: #FFFFFF;
                    font-size: 40px;
                    font-weight: 500;
                    // text-align: center;
                }
    
                .detail{
                    // width: 451px;
                    // height: 168px;
                    line-height: 26px;
                    color: #FDFDFD;
                    font-size: 16px;
                    letter-spacing: 2px;
                    margin: 2rem auto;
                }
    
                button{
                    width: 108px;
                    height: 38px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 48px;
                    border: 1px solid #FFFFFF;
                    // margin: auto;
                    text-align: center;
                }
    
                button:hover{
                    background-color: #FF701F;
                }
            }
        }
    }
}

