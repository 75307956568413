@media screen and (min-width: 1025px) {
    .investors_container{
        max-width: 1640px;
        // padding: 6rem 0;
        padding-top: 3rem;
        padding-bottom: 6rem;
        margin: auto;
        text-align: center;
    
        .title{
            height: 67px;
            line-height: 67px;
            color: #000;
            font-size: 48px;
            font-weight: 600;
            margin: auto;
        }
    
        .investor_name{
            margin: auto;  
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 3rem auto;
            .img-item{
                width: 20%;
                img{
                    width: 230px;
                    height: 79px;
                    margin: 1rem auto;
                }
            }
        }
    }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .investors_container{
        max-width: 1640px;
        padding: 8rem 0;
        margin: auto;
        text-align: center;
    
        .title{
            height: 67px;
            line-height: 67px;
            color: #000;
            font-size: 48px;
            font-weight: 600;
            margin: auto;
        }
    
        .investor_name{
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 6rem;
            .img-item{
                width: 50%;
                
                img{
                    width: 60%;
                    // height: 79px;
                    margin: 3rem auto;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .investors_container{
        max-width: 1640px;
        padding: 8rem 0;
        margin: auto;
        text-align: center;
    
        .title{
            height: 67px;
            line-height: 67px;
            color: #000;
            font-size: 40px;
            font-weight: 600;
            margin: auto;
        }
    
        .investor_name{
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 6rem;
            .img-item{
                width: 50%;
                
                img{
                    width: 100%;
                    margin: 3rem auto;
                }
            }
        }
    }
}