@media screen and (max-width: 500px) {
    .newsList .news{
        width: 80%;
    }

    .newsList .news:nth-child(n+2){
        display: none;
    }
}

@media screen and (min-width: 501px) and (max-width: 880px) {
    .newsList .news:nth-child(n+2){
        display: none;
    }
}

@media screen and (min-width: 881px) and (max-width: 1260px) {
    .newsList .news:nth-child(n+3){
        display: none;
    }
}


@media screen and (min-width: 501px){
    .newsList .news{
        width: 382px;
        height: 499px;
    }
}


        
.newsList{
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-evenly;
    .news{
        // width: 382px;
        // height: 499px;
        flex: 0 0 auto;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
        transition:  box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out;
        border-radius: 8px;
        img{
            width: 382px;
            height: 277px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .news_details{
            height: 222px;
            padding: 1rem;
            .news_time{
                width: 100px;
                height: 30px;
                color: #797979;
                font-size: 12px;
                font-weight: 400; 
                border-bottom: 1px solid #888888;
            }

            .news_info{
                height: 124px;
                color: #2B2B2B;
                font-size: 1rem;
                font-weight: 500;
                padding: 1rem 0;
            }

            button{
                height: 36px;
                width: 96px;
                color: #FFF;
                font-size: 12px;
                font-weight: 400;
                background-color: #FF9933;
                border-radius: 9999px;
            }

            button:hover{
                background-color: #FF701F;
            }
        }
    }

    .news:hover{
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
        transform: translateY(-2px);
    }
}