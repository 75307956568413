@media screen and (max-width: 768px) {
    .image-wrapper{
        width: 20rem;
    }
}

.image-wrapper{
    position: relative;
    margin: auto;
    overflow: hidden;

    img{
        transition: transform 1s linear;
    }

    .mask{
        height: 100%;
        width: 100%;
        background-color: rgba(1, 1, 1, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        transition: background-color 1s linear;
        z-index: 1;
    }
    
    .desc{
        color:#FFFFFF;
        font-weight:400;
        font-size:3rem;
        position:absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
    }
}

.image-wrapper:hover .mask{
    background-color: rgba(1, 1, 1, 0.4);
}

.image-wrapper:hover img{
    transform: scale(1.2);
}


.aboutPro_btn{
    border-color: #AEAEAE;
}

.aboutPro_btn:hover{
    border-color: #333333;
}

.aboutPro_btn:active{
    border-color: #333333;
}

.aboutPro_lbtn:hover{
    background-color: rgba(255, 112, 31, 1);
}

.aboutPro_lbtn:active{
    background-color: rgba(255, 112, 31, 1);
}