@media screen and (min-width: 1025px) {
    .img{
        transition: transform 5s linear;
    }

    @keyframes imgScale {
        0% {
            transform: scale(1);
          }
        100% {
            transform: scale(1.1);
        }
    }
    .imgActive{
        animation: imgScale 5s linear;
    }
    

    .carouselItem{
        .carouselCaption{
            position: absolute;
            // width: 40%;
            left: 10%;
            bottom: 8%;
            text-align: left;
            padding-top: 0;
            
            .detail{
                width: 60%;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .img{
        transition: transform 5s linear;
    }

    @keyframes imgScale {
        0% {
            transform: scale(1);
          }
        100% {
            transform: scale(1.1);
        }
    }
    .imgActive{
        animation: imgScale 5s linear;
    }
    .carouselCaption{
        position: absolute;
        width: 80%;
        left: 10%;
        bottom: 6%;
        text-align: left;
        padding-top: 0;

        .btn{
            font-size: 0.875rem;
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .carouselItem{
        img{
            height: 30rem;
            transform: scaleX(2);
        }

        .carouselCaption{
            position: absolute;
            width: 80%;
            left: 10%;
            bottom: 6%;
            text-align: left;
            padding-top: 0;
    
           
            .btn, .title,.detail{
                // display: none;
            }
            
        }
    }
    
}

@media screen and (max-width: 480px) {

    .carouselItem{
        img{
            height: 20rem;
            transform: scaleX(2);
        }

        .carouselCaption{
            position: absolute;
            // width: 76%;
            left: 14%;
            bottom: 4%;
            text-align: left;
            padding-top: 0;
    
           
            .btn, .title,.detail{
                // display: none;
            }
            
            .detail{
                width: 100%;
            }
        }
    }
    
}



.carouselItem{
    position: relative;

    img{
        width: 100%;
    }
    
    .carouselCaption{
        .title{
            font-weight: bold;
        }
    
        .btn{
            height: 40px;
            width: 96px;
            color: #FFFFFF;
            font-size: 0.875rem;
            font-weight: 400;
            background-color: rgba(255, 255, 255, 0.05);
            border: 1px solid #FFFFFF;
            margin: 0.75rem 0;
            border-radius: 0.25rem;
        }

        .btn_en{
            height: 40px;
            width: 96px;
            color: #FFFFFF;
            font-size: 0.875rem;
            font-weight: 400;
            background-color: rgba(255, 255, 255, 0.05);
            border: 1px solid #FFFFFF;
            margin: 0.75rem 0;
            border-radius: 0.25rem;
        }
    
        .btn:hover{
            background-color: rgba(0, 0, 0, 0.15);
        }
    
        // .btn:active{
        //     background-color: rgba(0, 0, 0, 0.15);
        // }
    
        .detail{
            font-weight: 400;
        }
    }

}


.carousel-control-next, .carousel-control-prev {
    width: 7.5%;
}

.carousel-control-next{
    justify-content: flex-start;
}

.carousel-control-prev{
    justify-content: flex-end;
}

