
@media screen and (max-width: 640px) {

    .founder_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 6rem 0;
            // height: 70rem;
            // display: flex;
            // flex-direction: column;
    
            .title_wrapper{
                color: #333333;
                text-align: center;
    
                .title{
                    height:134px;
                    line-height: 67px;
                    font-weight: 600;
                    font-size: 48px;
                    margin-bottom: 1rem;
                }
    
                .name{
                    height: 42px;
                    line-height: 42px;
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
    
            .ceo{
                padding-top: 2rem;
                // transform: scale(0.8);
                // transform-origin: top;
                width: 80%;
                margin: auto;
                img{
                    width: 449px;
                }
    
                .ceo_detail{
                    background-color: #fff;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    margin-top: 2rem;

                    .icon{
                        position: relative;
                    }
                    .icon::before{
                        content: "";
                        box-sizing: border-box;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 12px;
                        height: 12px;
                        background-color: #FF9933;
                        border-radius: 9999px;
                    }
    
                    .info{
                        padding: 30px 10px;
                        margin: auto;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 26px;
                        color: #656565;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 641px) and (max-width: 1023px) {

    .founder_container{
        .content{
            max-width: 1640px;
            margin:auto;
            padding: 4rem 0;
            display: flex;
            flex-direction: column;
    
            .title_wrapper{
                color: #333333;
                text-align: center;
    
                .title{
                    height:67px;
                    line-height: 67px;
                    font-weight: 600;
                    font-size: 48px;
                    margin-bottom: 1rem;
                }
    
                .name{
                    height: 42px;
                    line-height: 42px;
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
    
            .ceo{
                
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 3rem auto;
                img{
                    width: 60%;
                }
    
                .ceo_detail{
                    width: 80%;
                    background-color: #fff;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    margin-top: 2rem;
                    padding-bottom: 2rem;

                    .icon{
                        position: relative;
                    }
                    .icon::before{
                        content: "";
                        box-sizing: border-box;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 12px;
                        height: 12px;
                        background-color: #FF9933;
                        border-radius: 9999px;
                    }
    
                    .info{
                        margin: 45px 60px 0;
                        // margin-left: 60px;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 26px;
                        color: #656565;
                    }
                }
    
            }
        }
    }
}


@media screen and (min-width:1024px) and (max-width: 1279px){
    .founder_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 6rem 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .title_wrapper{
                color: #333333;
                margin-left: 2rem;
    
                .title{
                    height:67px;
                    line-height: 67px;
                    font-weight: 600;
                    font-size: 40px;
                    margin-bottom: 1rem;
                    word-wrap: break-word;
                    word-break: break-all;
                }
    
                .name{
                    height: 42px;
                    line-height: 42px;
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
    
            .ceo{
                position: relative;
                height: 487px;
                width: 449px;
                transform: scale(0.8);
                transform-origin: right;
                margin-right: 2rem;
                img{
                    // height: 487px;
                    // width: 449px;
                }
    
                .ceo_detail{
                    width: 695px;
                    // height: 264px;
                    background-color: #fff;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    position: absolute;
                    top: 40%;
                    left: -670px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    padding-bottom: 2rem;
                    .icon{
                        position: relative;
                    }
                    .icon::before{
                        content: "";
                        box-sizing: border-box;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 12px;
                        height: 12px;
                        background-color: #FF9933;
                        border-radius: 9999px;
                    }
    
                    .info{
                        width: 600px;
                        margin-top: 45px;
                        margin-left: 60px;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 26px;
                        color: #656565;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1279px) {

    .founder_container{
        .content{
            max-width: 1640px;
            margin: auto;
            padding: 10rem 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
    
            .title_wrapper{
                color: #333333;
                margin-left: 2rem;
    
                .title{
                    height:67px;
                    line-height: 67px;
                    font-weight: 600;
                    font-size: 48px;
                    margin-bottom: 1rem;
                }
    
                .name{
                    height: 42px;
                    line-height: 42px;
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
    
            .ceo{
                position: relative;
                height: 487px;
                width: 449px;
                margin-right: 2rem;
                img{
                    height: 487px;
                    width: 449px;
                }
    
                .ceo_detail{
                    width: 695px;
                    // height: 264px;
                    background-color: #fff;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    position: absolute;
                    left: -670px;
                    top: 40%;
                    padding-bottom: 2rem;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                
                    .icon{
                        position: relative;
                    }
                    .icon::before{
                        content: "";
                        box-sizing: border-box;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: 12px;
                        height: 12px;
                        background-color: #FF9933;
                        border-radius: 9999px;
                    }
    
                    .info{
                        width: 600px;
                        margin-top: 45px;
                        margin-left: 60px;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 26px;
                        color: #656565;
                    }
                }
    
                // .ceo_detail:hover{
                //     box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
                // }
            }
        }
    }
}


.founder_container{
    margin-left: -2rem;
    margin-right: -2rem;
    background-color: #F8F8F8;
}



