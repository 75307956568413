@media screen and (min-width: 1501px) {
    .video_container{
        background-color: rgba(34, 34, 34, 1);
        margin:0 -2rem;
        .content{
            max-width: 1640px;
            margin:4rem auto;
            padding: 6rem 0;
            text-align: center;
    
            .wrapper{
                width: 1440px;
                height: 1000px;
                margin: auto;
                // background-color: rgba(34, 34, 34, 1);
                padding: 4rem ;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .title{
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    font-size: 40px;
                    font-weight: 600;
                    
                }

    
                .video_desc{
                    color: #B8B7B7;
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 2px;
                    padding: 20px 0 6rem;
                }
    
                .video{
                    width: 90%;
                }
                // .video{
                //     position: relative;
                //     width: 96%;
                //     height: 500px;
                //     overflow: hidden;
                //     iframe{
                //         position: absolute;
                //         top: 0;
                //         left: 0;
                //         width: 100%;
                //         height: 100%;
                //         border: none;
                //     }
                // }
            }
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1500px) {
    .video_container{
        background-color: rgba(34, 34, 34, 1);
        margin:0 -2rem;
        .content{
            max-width: 1640px;
            margin: 6rem auto;
            padding: 6rem 0;
            text-align: center;
    
            .wrapper{
                width: 90%;
                height: 90%;
                margin: auto;
                // background-color: rgba(34, 34, 34, 1);
                padding: 4rem ;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .title{
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    font-size: 40px;
                    font-weight: 600;
                
                }
    
                .video_desc{
                    color: #B8B7B7;
                    font-size: 20px;
                    font-weight: 400;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                }
    
                .video{
                    width: 1038px;
                    height: 584px;
                }
                // .video{
                //     position: relative;
                //     width: 96%;
                //     height: 500px;
                //     overflow: hidden;
                //     iframe{
                //         position: absolute;
                //         top: 0;
                //         left: 0;
                //         width: 100%;
                //         height: 100%;
                //         border: none;
                //     }
                // }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .video_container{
        background-color: rgba(34, 34, 34, 1);
        margin:0 -2rem;
        .content{
            max-width: 1640px;
            margin: 4rem auto;
            padding: 6rem 0;
            text-align: center;
    
            .wrapper{
                width: 95%;
                // height: 90%;
                margin: auto;
                // background-color: rgba(34, 34, 34, 1);
                padding: 4rem ;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .title{
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    font-size: 40px;
                    font-weight: 600;
                    
                }
    
                .video_desc{
                    color: #B8B7B7;
                    font-size: 20px;
                    font-weight: 400;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                }
    
                .video{
                    // width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .video_container{
        background-color: rgba(34, 34, 34, 1);
        margin:0 -2rem;
        .content{
            max-width: 1640px;
            margin:4rem auto;
            padding: 6rem 0;
            text-align: center;
    
            .wrapper{
                width: 98%;
                // height: 1000px;
                margin: auto;
                // background-color: rgba(34, 34, 34, 1);
                padding: 6rem 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .title{
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    font-size: 40px;
                    font-weight: 600;
                    
                }
    
                .video_desc{
                    color: #B8B7B7;
                    font-size: 20px;
                    font-weight: 400;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                }
    
                .video{
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .video_container{
        background-color: rgba(34, 34, 34, 1);
        margin:0 -2rem;
        .content{
            max-width: 1640px;
            margin:4rem auto;
            padding: 6rem 0;
            text-align: center;
    
            .wrapper{
                width: 100%;
                // height: 1000px;
                margin: auto;
                // background-color: rgba(34, 34, 34, 1);
                padding: 4rem 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .title{
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    font-size: 40px;
                    font-weight: 600;
                    
                }
    
                .video_desc{
                    color: #B8B7B7;
                    font-size: 20px;
                    font-weight: 400;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                    padding: 20px 0 6rem;
                    letter-spacing: 2px;
                }
    
                .video{
                }
            }
        }
    }
}
  