
.header-light{
    width: 100%;
    z-index: 99;
    position: fixed;
    top: 0;
    // margin: 0 -2rem;
    padding: 0 2rem;
    background-color: rgba(255, 255, 255, .6);
    transition: top .3s;
}

.hidden{
    top:-100px
}